<template>
  <div v-if="question">
    <div class="flex justify-between pb-6" v-if="question.name">
      <label class="block text-sm font-medium leading-5 text-gray-700">{{
        question.name
      }}</label>
      <span class="text-sm leading-5 text-gray-500" v-if="question.required"
        >Required</span
      >
    </div>
    <table class="w-full -mx-2">
      <thead>
        <tr>
          <th>
            <!-- <label
              class="block text-left text-sm font-medium leading-5 text-gray-700 px-2"
            >
              {{ question.name }}
            </label> -->
          </th>
          <th
            v-for="opt in question.options"
            :key="opt.id"
            class="text-sm font-medium leading-5 text-gray-700 px-1"
            align="center"
          >
            {{ opt.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in question.rows" :key="row.id" class="group">
          <th
            class="text-sm text-left font-normal p-2 group-hover:bg-blue-50"
            style="max-width: 6rem;"
          >
            {{ row.label }}
          </th>
          <td
            v-for="opt in question.options"
            :key="opt.id"
            class="py-2 text-center px-1 align-middle group-hover:bg-blue-50"
            align="center"
          >
            <input
              type="checkbox"
              :name="question.id"
              v-model="ticks"
              :value="[row.id, opt.id]"
              :disabled="disabled"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'GridCheckboxView',
  props: {
    question: { type: Object },
    answer: { type: Array },
    disabled: { type: Boolean },
    v: { type: Object },
  },
  computed: {
    ticks: {
      get() {
        return this.answer || [];
      },
      set(pairs) {
        this.$emit('change', pairs);
      },
    },
  },
};
</script>
